:root {
  --valorant-color: #fb4b56;
}

.winner-category-valorant {
  color: var(--valorant-color);
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  margin-block-start: 0;
  margin-block-end: 10px;
}

.valorant-cta {
  background-color: var(--valorant-color);
  cursor: pointer;
}

.valorant-review,
.valorant-choice {
  border: 1px solid var(--valorant-color);
  border-radius: 2px;
}

.valorant-category h1,
.valorant-review svg,
.valorant-choice svg,
.valorant-category svg {
  color: var(--valorant-color);
}

.valorant-voting {
  background-image: url("../assets/valorant-voting-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.valorant-hr {
  border: 1px solid var(--valorant-color);
  width: 67px;
  margin: 12px auto;
}

.valorant-arrow {
  border-left: 12px solid var(--valorant-color);
  border-bottom: 12px solid var(--valorant-color);
  border-top: none;
  border-right: none;
  transition: 0.6s ease;
}

.valorant-footer {
  background-image: url("../assets/valorant-footer.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.valorant-inner-c {
  background-color: var(--valorant-color);
}

.valorant-section-description p,
.valorant-active-p {
  color: var(--valorant-color);
}

@media screen and (min-width: 1983px) {
  .valorant-voting {
    background-size: cover;
  }
}

.valorant-section-text {
  color: var(--valorant-color);
  text-align: center;
  font-family: "Barlow Semi Condensed", sans-serif;
}

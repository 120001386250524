.footer-container {
  display: flex;
  flex-direction: column;
  padding: 3rem 2.5rem;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-img-content {
  margin: 2rem 0;
}

.footer-img-content {
  margin: 0 30px;
  padding: 0;
}

.footer-img-content img {
  display: block;
  margin: 0;
}

.footer-presented-logo img {
  width: 220px;
}

.footer-support-logo img {
  width: 138px;
}

.footer-producer-logo img {
  width: 238px;
}

@media screen and (min-width: 947px) {
  .footer-container {
    justify-content: center;
    flex-direction: row;
  }

  .footer-content {
    max-width: 1024px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .footer-content-separator {
    width: 1px;
    height: 50px;
    background-color: #fff;
    margin: 0 20px;
  }

  .footer-content-valorant {
    justify-content: center;
  }
}

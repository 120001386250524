@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;400;600;700&display=swap");

html,
body {
  margin: 0 auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1d2c !important;
}

section {
  margin: 0 auto;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  outline: none;
}

html {
  scroll-behavior: smooth;
  scrollbar-color: #c1c1c4 #4d4f6e;
}

a,
a:visited,
a:hover {
  text-decoration: unset !important;
  color: unset !important;
}

.loginBtnMg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.alink:hover {
  color: #fff !important;
}

.alink {
  border-color: transparent;
  color: #fff !important;
}
.alink > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 288px;
  border-radius: 2px;
  height: 43px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-weight: 600;
  font-style: normal;
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
  box-shadow: 0 1px rgb(0 0 0 / 26%);
  border: 1px solid rgba(255, 255, 255, 0.16);
}

::-webkit-scrollbar {
  width: 1.1rem;
}

::-webkit-scrollbar-track {
  background: #282a3e;
}

::-webkit-scrollbar-thumb {
  background: #4d4f6e;
  border-radius: 8px;
}

::-ms-scrollbar {
  width: 1.1rem;
}

::-ms-scrollbar-track {
  background: #282a3e;
}

::-ms-scrollbar-thumb {
  background: #4d4f6e;
  border-radius: 8px;
}

::-o-scrollbar {
  width: 1.1rem;
}

::-o-scrollbar-track {
  background: #282a3e;
}

::-o-scrollbar-thumb {
  background: #4d4f6e;
  border-radius: 8px;
}

::-moz-scrollbar {
  width: 1.1rem;
}

::-moz-scrollbar-track {
  background: #282a3e;
}

::-moz-scrollbar-thumb {
  background: #4d4f6e;
  border-radius: 8px;
}

hr {
  transition: 0.6s ease;
}

.judges-title {
  padding-top: 30px;
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.arrow-up {
  display: inline-block;
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  margin-left: 40px;
  margin-bottom: -5px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  transition: 0.6s ease;
}

.arrow-down {
  display: inline-block;
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-left: 40px;
  margin-bottom: 0;
  cursor: pointer;
  transition: 0.6s ease;
}

.main-logo {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.main-logo img:not(:first-child) {
  margin: 0 0 0 2rem;
}

.main-logo .main-logo-sponsor {
  width: 75%;
  max-width: 170px !important;
}

.inactive-game-option .game-link .icons > img {
  width: 33.33%;
}

.pd-50 {
  padding: 50px 0;
}

.align-center {
  text-align: center;
}

.display-block {
  display: block;
}

.stepper {
  flex: 1;
}

.step-line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
}

.outside-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #40415a;
}

.inner-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #282a3e;
}

.step-line p {
  font-size: 14px;
  font-weight: 700;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: inline-block;
  margin-block-start: 0;
  margin-block-end: 0;
  max-width: 240px;
  text-align: left;
  line-height: 1;
}

.step-fig {
  margin-right: 0.5rem;
}

.vert-line {
  border-left: 3px solid #282a3e;
  display: block;
  margin-left: 8.7px;
  padding: 20px 0;
}

.inactive-p {
  cursor: pointer;
  color: #fff;
}

.active-circle {
  background-color: #fff;
}

.unchecked {
  background-color: #282a3e;
}

.unchecked-p {
  color: rgba(255, 255, 255, 0.16);
}

.judge-description {
  background-color: #000;
  width: 200px;
  height: 230px;
}

.chosen-review-card,
.judge-card {
  background-color: rgba(40, 42, 62, 1);
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  margin: 10px;
}

.judge-card {
  width: 200px;
  height: 238px;
  padding: 20px 10px;
  vertical-align: top;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.judge-card-avt-area {
  justify-content: center;
  align-items: center;
  display: flex;
}

.chosen-card-avt,
.judge-card-avt {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.16);
  border: 8px solid #1c1d2c;
  background-size: cover;
}

.chosen-card-avt {
  background-size: cover;
}

.judge-card-avt-outline {
  border: 2px solid rgba(56, 59, 81, 1);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.judge-card-info h1 {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin-block-end: 2px;
}

.judge-card-info h2 {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(148, 140, 184, 1);
  margin-block-start: 0;
}

.section-title {
  display: block;
  text-align: center;
}

.active-game-title h1,
.section-title h1 {
  font-size: 72px;
  line-height: 90px;
  font-weight: 700;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-block-start: 0;
  margin-block-end: 20px;
  color: #fff;
}

.container {
  display: block;
  text-align: center;
  padding: 0 50px;
}

.game-switch {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.active-game-title h1 {
  text-shadow: 1px 1px 5px #53535353;
  width: 100%;
  text-align: left;
  max-width: 710px;
}

.inactive-game-option,
.active-game-option {
  display: flex;
  padding: 30px 0;
}

.active-game-option {
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.inactive-game-option {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.inactive-game-option,
.active-game-option {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 1s ease;
}

.valorant-hero {
  background-image: url("../assets/valorant-bg-banner.jpg");
}

.cs-inactive {
  background-image: url("../assets/csgo-inactive.png");
}

.cs-hero {
  background-image: url("../assets/csgo-bg-banner.jpg");
}

.valorant-inactive {
  background-image: url("../assets/valorant-inactive.png");
}

.inactive-game-option {
  cursor: pointer;
}

.active-game-option p {
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  font-family: "Barlow Semi Condensed", sans-serif;
  text-align: left;
  width: 100%;
  max-width: 650px;
  color: #fff;
  display: flex;
}

.inactive-game-shape svg,
.active-game-shape svg {
  width: 176px;
  height: 228px;
}

.inactive-game-shape,
.active-game-shape {
  width: 100%;
  height: 100%;
  text-align: center;
}

.col80,
.col-20 {
  display: inline-block;
}

.col-80 {
  text-align: left;
  width: 100%;
  padding-left: 40px;
}

.col-20 {
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.col-20 p,
.col-20 svg,
.col-20 img {
  display: block;
  margin: 20px auto;
}

.col-20 img {
  width: 100%;
}

.col-20 p {
  text-transform: uppercase;
  margin: auto;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  font-style: normal;
  font-family: "Barlow Semi Condensed", sans-serif;
  text-align: center;
  color: #fff;
}

.col-20 svg {
  width: 160px;
}

.icons {
  text-align: center;
  margin: 0 auto;
}

.game-icon {
  margin-top: 1rem;
  height: 100px;
}

.voting-container {
  flex-direction: column;
}

.cta-button {
  border-color: transparent;
  color: #fff;
  border-radius: 2px;
  width: 288px;
  height: 43px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-weight: 600;
  font-style: normal;
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: 0 1px rgba(0, 0, 0, 0.26);
}

.cta-button:hover {
  opacity: 0.9;
}

.inactive-button {
  opacity: 0.2;
  background-color: #40415a;
  color: #fff;
}

.inactive-button:hover {
  opacity: 0.2 !important;
}

.button-wrapper-area {
  margin: 30px 0;
}

.margin-auto {
  margin: 30px auto;
  text-align: center;
}

.section-description p {
  margin: auto;
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  font-family: "Barlow Semi Condensed", sans-serif;
  text-align: center;
  width: 100%;
  max-width: 1200px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.award-category {
  text-align: left;
  margin-top: 30px;
  padding-left: 40px;
}

.award-category h2,
.award-category svg {
  display: inline-block;
  margin: 0 12px;
}

.award-category svg {
  margin-bottom: -2px;
}

.award-category h2 {
  font-size: 48px;
  line-height: 90px;
  font-weight: 700;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
  color: #fff;
}

.category-candidates {
  width: 100%;
  max-width: 1380px;
  display: inline-block;
  height: 100%;
}

.candidate-card-wrapper {
  margin: 14px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background-color: #282a3e;
}

.candidate-card-wrapper:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  transition: 0.5s ease;
}

.choose-this-one {
  background-color: rgba(0, 0, 0, 0.78);
  border-radius: 2px;
  width: 560px;
  height: 260px;
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
}

.display-flex {
  animation: flex 0.7s;
  display: flex;
}

.display-none {
  display: none;
}

.review-this-card svg,
.choose-this-one svg {
  width: 42px;
  margin-right: 25px;
}

.review-this-card p,
.choose-this-one p {
  display: inline-block;
  text-transform: uppercase;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  color: #fff;
  margin-block-start: 0;
  margin-block-end: 0;
}

.candidate-card {
  background-color: #282a3e;
  border-radius: 2px;
  text-align: left;
  width: 560px;
  height: 260px;
  display: flex;
}

.candidate-card:hover {
  background-color: #27293f;
}

.candidate-card-avt-area,
.candidate-card-info {
  /* display: inline-table; */
}

.candidate-card-avt-area {
  justify-content: center;
  align-content: center;
  display: flex;
}

.candidate-card-avt-outline {
  margin: auto;
  border: 1px solid #4d4f6e;
  border-radius: 50%;
}

.candidate-card-avt {
  width: 150px;
  height: 150px;
  border: 8px solid #1c1d2c;
  background-color: rgba(0, 0, 0, 0.16);
  background-position: center;
  border-radius: 50%;
  background-size: cover;
}

.candidate-card-info {
  width: 100%;
  height: 100%;
}

.info-wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
  text-align: left;
  /* background-image: url("../assets/candidate-bg.png"); */
  background-position: center;
  background-size: 55%;
  background-repeat: no-repeat;
}

.candidate-name,
.candidate-org {
  display: block;
  width: 100%;
}

.candidate-card-avt-area {
  width: 100%;
  max-width: 252px;
  height: 100%;
  max-height: 260px;
}

.candidate-card-info h1 {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  margin-block-start: 0;
  margin-block-end: 0;
}

.candidate-card-info h2 {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(148, 140, 184, 1);
  margin-block-start: 0;
  margin-block-end: 0;
}

.judge-sm {
  position: absolute;
  z-index: 1;
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
}

.judge-sm ul {
  display: flex;
  width: 110px;
  justify-content: space-around;
  align-items: center;
}

.judge-sm li {
  display: block;
  margin: 26px auto;
}

.sm li {
  display: inline;
}

.sm svg {
  margin-right: 12px;
}

.judge-sm svg,
.sm svg {
  width: 22px;
  color: #fff;
}

.category-candidates,
.steps-wrapper {
  display: inline-block;
  margin: 0 auto;
}

.steps-wrapper {
  text-align: center;
  /* margin-left: 20px; */
  vertical-align: top;
  height: 100%;
}

.steps-wrapper > div:first-of-type {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.voting {
  display: flex;
}

.advertising {
  margin: 20px auto 0;
  text-align: center;
  width: 100%;
}

.banner {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}

.banner img {
  width: 100%;
  max-width: 970px;
  cursor: pointer;
}

.opacity-015 {
  opacity: 0.15;
}

.event-info p {
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  font-family: "Barlow Semi Condensed", sans-serif;
  text-align: left;
  color: #fff;
}

.event-info p,
.event-info-title h3 {
  width: 100%;
}

.event-info-hr {
  width: 100%;
  margin: 45px auto;
}

.event-info-title h3 {
  margin-block-start: 0;
  margin: 0;
  font-size: 96px;
  line-height: 98px;
  font-weight: 400;
  font-weight: 700;
  font-style: normal;
  font-family: "Barlow Semi Condensed", sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

.white {
  color: #fff;
}

.black {
  color: 000;
}

.countdown-button-area,
.countdown {
  display: inline-block;
  vertical-align: middle;
  margin: 20px auto;
  text-align: center;
  width: 100%;
}

.countdown {
  margin-left: auto;
  margin-right: auto;
}

.countdown-cta {
  display: block;
}

.countdown-button svg {
  color: #fff;
  width: 22px;
  vertical-align: middle;
  margin-right: 10px;
}

.event-info .countdown h3 {
  font-weight: 700;
  font-family: "Barlow Semi Condensed", sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.countdown span {
  font-size: 48px;
  font-weight: 700;
  font-family: "Barlow Semi Condensed", sans-serif;
  color: #fff;
  text-transform: uppercase;
}

.countdown-button {
  cursor: pointer;
  background-color: #9146ff;
  color: #fff;
  margin: 10px;
}

.category-review-name h1 {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.chosen-review-card {
  width: 225px;
  height: 280px;
  vertical-align: top;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.chosen-review-card,
.review-this-card {
  margin: 10px;
}

.mg-top-30 {
  margin-top: 30px;
}

.review-this-card {
  width: 225px;
  height: 280px;
  padding: 0;
  margin: 0;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.78);
  cursor: pointer;
}

.btn-area {
  width: 100%;
}

.voted-btn {
  margin: 10px;
  width: 330px !important;
}

.event-info {
  width: 45%;
  text-align: left;
}

.event-info h3 {
  color: #000;
  font-size: 36px;
  line-height: 43px;
  font-weight: 700;
  font-style: normal;
  font-family: "Barlow Semi Condensed", sans-serif;
  text-transform: uppercase;
  text-align: left;
  margin-block-end: 5px;
}

@media screen and (max-width: 1380px) {
  .category-candidates {
    width: 100%;
    max-width: 1020px;
    display: inline-block;
  }

  .award-category {
    padding-left: 160px;
  }

  .candidate-card-wrapper {
    margin: 15px;
    display: inline-block;
  }

  .choose-this-one,
  .candidate-card {
    width: 460px;
    height: 240px;
  }

  .container {
    padding: 0;
  }

  .steps-wrapper {
    margin-left: 0;
  }

  .review-this-card,
  .chosen-review-card {
    width: 215px;
    height: 270px;
  }

  .event-info {
    width: 50%;
  }

  .col-20 {
    width: 20%;
  }
}

@media screen and (min-width: 947px) and (max-width: 1366px) {
  .col-80 {
    padding-left: 50px !important;
  }

  .active-game-title h1 {
    font-size: 42px;
    line-height: 44px;
  }

  .active-game-option p {
    font-size: 16px;
    line-height: 20px;
  }

  .banner {
    width: 947px;
  }
}

@media screen and (max-width: 1919px) {
  .col-80 {
    padding-left: 140px;
  }

  .choose-this-one,
  .candidate-card {
    width: 430px;
  }

  .category-candidates {
    max-width: 1020px;
  }
}

@media screen and (min-width: 1919px) {
  .active-game-option {
    width: 85vw;
  }

  .col-80 {
    padding-left: 120px;
  }
}

@media screen and (max-width: 1200px) {
  .category-candidates {
    width: 100%;
    max-width: 730px;
    padding: 20px;
    display: inline-block;
  }

  .candidate-card-avt {
    width: 80px;
    height: 80px;
    background-size: cover;
  }
}

@media screen and (max-width: 1015px) {
  .category-candidates {
    width: 100%;
    max-width: 530px;
    padding: 20px;
    display: inline-block;
  }
}

@media screen and (max-width: 946px) {
  .banner img {
    max-width: unset;
  }

  .inactive-game-option,
  .active-game-option {
    width: 100%;
    display: block;
    position: relative;
  }

  .valorant-inactive {
    background-image: none;
    background-color: #fb4b56;
  }

  .cs-inactive {
    background-image: none;
    background-color: #49a3fd;
  }

  .main-logo {
    justify-content: center;
  }

  .main-logo-img {
    padding-top: 40px;
    width: calc(50% - 2rem);
  }

  .main-logo img:not(:first-child) {
    width: calc(45% - 2rem);
  }

  .game-icon {
    height: auto;
  }

  .col-80-p,
  .section-title,
  .event-info-p,
  .event-info-title,
  .event-info-hr,
  .col-80 > div {
    padding-right: 30px;
    padding-left: 30px;
  }

  .section-title h1 {
    font-size: 42px;
    line-height: 50px;
  }

  .award-category h2 {
    margin-top: 3px;
    font-size: 40px;
    line-height: 50px;
  }

  .event-premiere {
    padding: 30px 0;
  }

  .event-info-hr {
    width: auto;
  }

  .event-info-title h3 {
    font-size: 40px;
    line-height: 44px;
  }

  .active-game-option h1,
  .active-game-option p,
  .active-game-option > div {
    text-align: center;
  }

  .category-candidates,
  .award-category,
  .col-80 {
    padding-left: 0;
    padding-right: 0;
  }

  .category-candidates,
  .active-game-option p,
  .active-game-title h1 {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .event-info,
  .countdown-button-area {
    display: block;
  }

  .countdown-button-area {
    margin: 20px auto;
  }

  .col-20 {
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .icons {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .icons p {
    font-size: 22px;
  }

  .icons svg {
    height: 50px;
    margin-left: 12px;
  }

  .icons div {
    display: inline-block;
  }

  .steps-wrapper {
    display: none !important;
  }

  .event-info,
  .award-category,
  section {
    width: 100%;
    display: block;
    text-align: center;
  }

  .event-info {
    padding: 30px;
  }

  .container {
    width: 100%;
  }

  .choose-this-one,
  .candidate-card {
    width: 320px;
  }

  .candidate-card-avt {
    width: 100px;
    height: 100px;
    background-size: cover;
  }

  .arrow-down,
  .arrow-up {
    width: 20px;
    height: 20px;
    transition: 0.6s ease;
  }
}

@media screen and (max-width: 767px) {
  .arrow-down,
  .arrow-up {
    width: 20px;
    height: 20px;
    margin: 0px auto 30px auto;
    display: block;
    transition: 0.6s ease;
  }
}

@media screen and (min-width: 947px) {
  .game-switch {
    flex-direction: row;
    justify-content: space-between;
  }

  .main-logo img {
    width: 100%;
    max-width: 200px !important;
  }

  .inactive-game-option,
  .active-game-option {
    height: 100%;
    min-height: 100vh;
  }

  .active-game-option {
    width: 85%;
    background-position: right;
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
    z-index: 3;
    box-shadow: #4d4f6e;
  }

  .active-game-option + .inactive-game-option {
    z-index: 2;
    width: 50%;
    margin-left: -10.5%;
  }

  .inactive-game-option {
    display: flex;
    justify-content: center;
    width: calc(16.666667% + 6.5%);
    margin-left: -10.5%;
  }

  .inactive-game-option .game-link,
  .inactive-game-option .game-link .icons {
    display: flex;
    justify-content: center;
  }

  .inactive-game-option .game-link .icons {
    width: 66.666667%;
    flex-direction: column;
    align-items: center;
  }

  .inactive-game-option:hover .game-link .icons {
    transform: scale(110%);
    transition: 0.2s ease;
  }

  .inactive-game-option .game-link .icons > img {
    width: 85%;
  }

  .banner {
    width: 1024px;
    max-width: 1024px;
  }

  .advertising {
    margin: 60px auto;
  }

  .event-premiere {
    padding: 2rem 5rem;
    flex-direction: column;
  }

  .event-info-title h3 {
    font-size: 48px;
    line-height: 50px;
    margin: 0 0 2rem 0;
  }
}

@keyframes flex {
  from {
    display: none;
    opacity: 0;
  }
  to {
    display: flex;
    opacity: 1;
  }
}

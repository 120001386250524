.cs-cta {
  background-color: #1285f8;
  cursor: pointer;
}

.cs-review,
.cs-choice {
  border: 1px solid #49a3fd;
  border-radius: 2px;
}

.cs-section-description p,
.cs-active-p,
.cs-category h1,
.cs-review svg,
.cs-choice svg,
.cs-category svg {
  color: #49a3fd;
}

.cs-section-text {
  color: #49a3fd;
  text-align: center;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.cs-voting {
  background-image: url("../assets/cs-voting-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cs-hr {
  border: 1px solid #49a3fd;
  width: 67px;
  margin: 12px auto;
}

.cs-arrow {
  border-left: 12px solid #49a3fd;
  border-bottom: 12px solid #49a3fd;
  border-top: none;
  border-right: none;
  transition: 0.6s ease;
}

.winner-category-cs {
  color: #49a3fd;
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  margin-block-start: 0;
  margin-block-end: 10px;
}

.cs-footer {
  background-image: url("../assets/csgo-footer.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.cs-inner-c {
  background-color: #49a3fd;
}

@media screen and (min-width: 1983px) {
  .cs-voting {
    background-size: cover;
  }
}
